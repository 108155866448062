import axios from "axios";
import { useEffect, useState } from "react";
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime";
import { NotFound, NotFoundIcon, NotFoundText, Table, Td, Tr } from "./style";
const ReferralRewards = () => {
    const userId = JSON.parse(localStorage.getItem('_currentInfo')).user_id;
    const currency = JSON.parse(localStorage.getItem('OMS__CUR')).symbol;
    const [rewards, setRewards] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    useEffect(() => {
        axios.post('https://api.betaoptionss.com/user/getRawards', { userID: userId })
            .then((response) => {
              
                if (response.data.data.length === 0) {
                    setIsEmpty(true)
                }
                else {
                    setRewards(response.data.data);
                    setIsEmpty(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [userId])
    const getStatus = (bool) => {
        if (bool) {
            return (<div>Completed</div>)
        }
        else {
            return (<div>Not Completed</div>)
        }
    }

    return (<>

        <div className={`${styles.card} ${styles.half}`}>
            <div className={styles.card_inner}>
                <div className={styles.card_content}>
                    <div className={styles.card_header}>
                        <div className={styles.card_header_text}>Rewards</div>
                    </div>
                    <div className={styles.card_body}>
                        <div className={styles.table}>
                            {isEmpty ? <><NotFound>
                                <NotFoundIcon className="fi fi-rr-search-alt">
                                </NotFoundIcon>
                                <NotFoundText>No Reward found</NotFoundText>
                            </NotFound></> : <> 
                            <Table className={`table`}>
                                    <thead>
                                        <Tr>
                                            <th>Reward ID</th>
                                            <th>Date</th>
                                            <th>Task</th>
                                            <th>Bonus</th>
                                            <th>Status</th>
                                        </Tr>
                                    </thead>
                                    <tbody>
                                        {rewards.map((reward) => {
                                            return <Tr key={reward.rewardId}>
                                                <Td>{reward.rewardId}</Td>
                                                <Td>{convertTime(reward.date)}</Td>
                                                <Td>{reward.task}</Td>
                                                <Td><div>{currency}{reward.amount}</div></Td>
                                                <Td>{getStatus(reward.status)}</Td>
                                            </Tr>
                                        }
                                        )}
                                    </tbody>
                                </Table></>}
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </>);
}

export default ReferralRewards;