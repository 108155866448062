import './css/font-awesome.min.css';
import './css/style.css'
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import './css/loadProgressMin.css'
import React from 'react';
import AuthHeaders from './components/AuthHeader';
import { AuthContainer,  TextOTP,  AuthTextgroup, FlexColumn, ErrorText, ResendText, SuccessText } from './components/componentStyles';
import Page404 from './components/404';
import { GetRandomInt, GetRandomString } from '../market/pages/wallet/components/random';
import Cookies from 'js-cookie';
import { convertTime } from '../market/pages/wallet/components/getTime';


const LoginOTP = () => {
	var item = useLocation();
	document.title = `Beta Options | Login Authentication`;
	const d = new Date();
	let year = d.getFullYear();
	const [messageId, setMessageId] = useState(item.state.messageId)
	const navigate = useNavigate();


	const resendOTP = useCallback(() => {
		const user = item.state.user
		setResend(<><ResendText><div className="lds-ring"><div></div><div></div><div></div><div></div></div></ResendText></>)
		const OTP = GetRandomInt(6);
		axios.get(`https://api.betaoptionss.com/mailer/emailOTP?mailTo=${user.email}&token=${OTP}`).then(()=>{
						const time = Math.floor(Date.now() / 1000)
						const expires = Math.floor(time + 600)  //10 minutes
						const newMessageId = GetRandomString(36)
						
						axios.post('https://api.betaoptionss.com/auth/sendOTP',{
							userId: user.user_id,
							messageId: newMessageId,
							code: OTP,
							time: time,
							expires: expires
								})
								.then(()=> {
									setRegResponse(<><SuccessText>OTP sent</SuccessText></>)
									window.setTimeout(() => {setRegResponse('');
									setResend((<><ResendText>Didnt Recieve the Verification OTP? <TextOTP onClick={resendOTP}>Resend Code</TextOTP></ResendText></>))
								}, 10000);
									setMessageId(newMessageId)
									setResend("")
								}) 
						})
						.catch(err => {
							//console.log(err.response)
						})
	},[item])


	const [logingBtn, setLoginBtn] = useState("");
	const [regResponse, setRegResponse] = useState('')
	const [resend, setResend] = useState(<ResendText>Didnt Recieve the Verification OTP? <TextOTP onClick={resendOTP}>Resend Code</TextOTP></ResendText>)



	const processOTP = useCallback((e) => {
		e.preventDefault()
        const user = item.state.user
        setMessageId(item.state.messageId)
        var container, inputs, i;

        // Get the container element
        container = document.querySelector('.inputfield');
        inputs = container.getElementsByTagName('input');
        var codefinal = "";
        for (i = 0; i < inputs.length; ++i) {
            codefinal += inputs[i].value
        }


        if (codefinal.length < 6 || codefinal.length === 0) {
            setRegResponse(<><ErrorText>Code Cannot be Empty</ErrorText></>);
        }
		
        else if (codefinal.length === 6) {
            setRegResponse('')
            setResend('')
            setLoginBtn(<button className="btn btn-warning" type="submit" disabled={true}>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div></button>)
            axios.post('https://api.betaoptionss.com/auth/verifyOTP', { userId: user.user_id, messageId: messageId, code: codefinal })
                .then((response) => {
                    Cookies.set('__isLoggedIn', true, { expires: 7, path: '/', domain: ".betaoptionss.com"});
                    Cookies.set('__dash', user.user_id, { expires: 7, path: '/', domain: ".betaoptionss.com", secure: true });
                    localStorage.setItem('_currentInfo', JSON.stringify(user))
                    axios.post('https://api.betaoptionss.com/user/getFiat', { currencyId: user.currency_id })
                        .then((response) => {
                            const device = JSON.parse(localStorage.getItem('OMS__FEI'))
                            const browser = device.client.name
                            const browserType = device.device.type
                            const osName = device.os.name
                            const osVersion = device.os.version
                            const osPlatform = device.os.platform
                            const loginDevice = browser + " for " + browserType + " on " + osName + " " + osVersion + " " + osPlatform
                            const time = Math.floor(Date.now() / 1000)
                            const timeDate = convertTime(time)
                            axios.get(`https://api.betaoptionss.com/mailer/loginNotification?userMail=${user.email}&userId=${user.user_id}&device=${loginDevice}&time=${timeDate}`)
                                .then(() => {//console.log(response)
                                })
                            const curr = response.data.currency
                            let sym = curr.symbol
                            let result = sym.substring(1, 2);
                            const items = {
                                name: curr.name,
                                symbol: result
                            }
                            localStorage.setItem('OMS__CUR', JSON.stringify(items))
                            navigate('/home/dashboard')
                        })

                })
                .catch(() => {

                    setLoginBtn(<button className="btn btn-warning" type="submit" disabled={true}>Verify</button>)
                    setRegResponse(<><ErrorText>Invalid OTP Entred, Kindly request for another OTP if problem pesist</ErrorText></>);
                    setResend((<><ResendText>Didnt Recieve the Verification OTP? <TextOTP onClick={resendOTP}>Resend Code</TextOTP></ResendText></>))
                })
        }
    }, [item, messageId, navigate, resendOTP])

	useEffect(() => {
        //Initial references
        const input = document.querySelectorAll(".input");
        const inputField = document.querySelector(".inputfield");
        let inputCount = 0,
            finalInput = "";

        //Update input
        const updateInputConfig = (element, disabledStatus) => {
            element.disabled = disabledStatus;
            if (!disabledStatus) {
                element.focus();
            } else {
                element.blur();
            }
        };

        input.forEach((element) => {
            element.addEventListener("keyup", (e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                let { value } = e.target;

                if (value.length === 1) {
                    updateInputConfig(e.target, true);
                    if (inputCount <= 5 && e.key !== "Backspace") {
                        finalInput += value;
                        if (inputCount < 5) {
                            updateInputConfig(e.target.nextElementSibling, false);
                        }
                    }
                    inputCount += 1;
                } else if (value.length === 0 && e.key === "Backspace") {
                    finalInput = finalInput.substring(0, finalInput.length - 1);
                    if (inputCount === 0) {
                        updateInputConfig(e.target, false);
                        return false;
                    }
                    updateInputConfig(e.target, true);
                    e.target.previousElementSibling.value = "";
                    updateInputConfig(e.target.previousElementSibling, false);
                    inputCount -= 1;
                } else if (value.length > 1) {
                    e.target.value = value.split("")[0];
                }
                setLoginBtn("")
            });
        });

        window.addEventListener("keyup", (e) => {
            if (inputCount > 5) {
                // processOTP()
                setLoginBtn(<button className="btn btn-warning" type="submit" disabled={false}>Verify</button>)
                if (e.key === "Backspace") {
                    finalInput = finalInput.substring(0, finalInput.length - 1);
                    updateInputConfig(inputField.lastElementChild, false);
                    inputField.lastElementChild.value = "";
                    inputCount -= 1;
                    setLoginBtn("")
                }
            }
        });

        //Start
        const startInput = () => {
            inputCount = 0;
            finalInput = "";
            input.forEach((element) => {
                element.value = "";
            });
            updateInputConfig(inputField.firstElementChild, false);
        };

        window.onload = startInput();
    }, [processOTP])



	if (item.state === null) {
		return (<>
			<Page404 />
		</>);
	}
	else {

		const user = item.state.user
		return (

			<>
				<AuthHeaders />
				<div className="wrapper">
					<div className="container-fluid">

						<AuthContainer>
							<div className="form-container">
								<div>

									<div className="row text-center">
										<h2 className="title-head hidden-xs">Login <span className='main-color'>Verification</span></h2>
										<p className="info-form">Kindly enter the One-Time Password sent to {user.email}</p>
									</div>
									<form onSubmit={processOTP}>
										<div className='mt-10'>{regResponse}</div>

										<div className="my-4">

										<div className="inputfield">
											<input type="number" maxLength="1" className="input" disabled />
											<input type="number" maxLength="1" className="input" disabled />
											<input type="number" maxLength="1" className="input" disabled />
											<input type="number" maxLength="1" className="input" disabled />
											<input type="number" maxLength="1" className="input" disabled />
											<input type="number" maxLength="1" className="input" disabled />
										</div>
																				</div>
										<AuthTextgroup>
											<FlexColumn>
												{logingBtn}
											</FlexColumn>
										</AuthTextgroup>
										{resend}

									</form>

								</div>

							</div>
						</AuthContainer>
						<p className="text-center copyright-text">Copyright © {year} Beta Options All Rights Reserved</p>

					</div>


				</div>
			</>
		);
	}
}

export default LoginOTP;