import axios from "axios";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import styles from '../../style/main.module.css'
import { FlexColumn, RowColumn } from "./style";
const ReferralSummary = () => {
    const currency = JSON.parse(localStorage.getItem('OMS__CUR'));
    const userId = JSON.parse(localStorage.getItem('_currentInfo')).user_id;
    const [totalReferrals, setTotalReferrals] = useState()
    const [totalEarnings, setTotalEarnings] = useState()
    const [totalReferralsCount, setTotalReferralsCount] = useState()
    useEffect(() => {
        axios.post('https://api.betaoptionss.com/user/getReferrals', { userID: userId })
            .then((response) => {
                setTotalReferrals(response.data.data.length)
                return response.data.data
            })
            .then((data) => {
                var sum = 0;
                var valid = 0
                for (let i = 0; i < data.length; i++) {
                    sum = Number(sum) + Number(data[i].amount);
                    if (data[i].rewarded) {
                        valid = Number(valid) + 1

                    }
                }
                setTotalReferralsCount(valid)
                setTotalEarnings(sum)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [userId])
    return (<>
        <div className={`${styles.card} ${styles.lg}`}>
            <div className={styles.card_inner}>
                <div className={styles.card_content}>
                    <RowColumn>
                        <FlexColumn>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={styles.coin_title}>Total Referral Bonus ({currency.name})</div>

                                </div>
                            </div>
                            <div className={styles.card_body}>
                                <Link className={styles.coin_ad} to="#">
                                    <div data-bn-type="text" className={styles.coin_price}>
                                        <CurrencyFormat value={totalEarnings} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                            isNumericString={true} thousandSeparator={true} prefix={currency.symbol} /> </div>

                                </Link>
                            </div>
                        </FlexColumn>
                        <FlexColumn>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={styles.coin_title}>Total Referrals</div>

                                </div>
                            </div>
                            <div className={styles.card_body}>
                                <Link className={styles.coin_ad} to="#">
                                    <div data-bn-type="text" className={styles.coin_price}>
                                        {totalReferrals} </div>

                                </Link>
                            </div>
                        </FlexColumn>
                        <FlexColumn>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={styles.coin_title}>Successful Referral</div>

                                </div>
                            </div>
                            <div className={styles.card_body}>
                                <Link className={styles.coin_ad} to="#">
                                    <div data-bn-type="text" className={styles.coin_price}>
                                        {totalReferralsCount}</div>

                                </Link>
                            </div>
                        </FlexColumn>
                    </RowColumn>
                </div>
            </div>
        </div>
    </>);
}

export default ReferralSummary;