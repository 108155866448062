import axios from "axios";
import { useEffect, useState } from "react";
import { NotFound, NotFoundIcon, NotFoundText } from "./dashboardComponent";

const WalletConnetMini = (props) => {
        const id =props.id;
        const [walletInfo, setWalletInfo] = useState([])
        const [isEmpty, setIsEmpty] = useState(true)
    useEffect(() => {
        axios.post('https://api.betaoptionss.com/user/getConnectWallet',{ userId:id}).then((response) => {
            if (response.data.length > 0) {
                setWalletInfo(response.data.data)
                setIsEmpty(false)
            }
            else {
                setWalletInfo([])
                setIsEmpty(true)
            }
        }).catch((error) => {
            console.log(error.response)
        });

    },[])
    return ( <>
    {isEmpty ? <><NotFound>
                <NotFoundIcon className="fi fi-sr-link">
                </NotFoundIcon>
                <NotFoundText>No Wallet Linked</NotFoundText>
            </NotFound></> : <></>}
    </> );
}
 
export default WalletConnetMini;