import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogModal from "../blogs/blogModal";
import Skeleton from "../skeleton/skeleton";
import { Card, CardContent, CardHeader, NewsBody, NewsContent, NewsDiv, NewsHeader, NewsImage } from "./dashboardComponent";

const NewsMini = () => {
   const [newsInfo, setNewsInfo ] = useState()
   const [isLoading, setIsLoading] = useState(true)
   const [blogInfo, setBlogInfo] = useState([])
    useEffect(()=>{
        axios.get('https://min-api.cryptocompare.com/data/v2/news/?lang=EN')
    .then((response) => {  
        setIsLoading(true)
        return response.data.Data
      })
    .then((data) => {
        setNewsInfo(data.slice(0, 3)) 
        setIsLoading(false)
        //console.log(data.slice(0, 5));
    })
    },[])
    
    return ( <>
   {isLoading ? <Skeleton type="NewsList"/> : 
   newsInfo && newsInfo.map((item) => (
        <Card key={item.id}>
            <CardContent>
                <CardHeader>
                    <NewsDiv>
                        <NewsImage src={item.imageurl}/>
                        <NewsContent>
                            <NewsHeader>
                                 {item.title}
                            </NewsHeader>
                            <NewsBody>
                                {(item.body).substring(0, 30)}... <Link to={'#'} data-bs-toggle="modal" data-bs-target="#newsModal"  onClick={(e) => {setBlogInfo(item) }}>Read More</Link>
                            </NewsBody>
                        </NewsContent>
                    </NewsDiv>
                </CardHeader>
                </CardContent> 
        </Card>
    ))}
    <BlogModal blog={blogInfo}/>
    </> );
}
 
export default NewsMini;