import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../css/index.module.css'
import { CardBody, CardContent, CardHeader, CardHeaderText, CardInner, CardSM, H3, Icon, IconBody, IconDiv, IndexSection, IndexSectionWrapper, Paragraph, ParagraphText, Row, SearchResult, Textbox, TextFlex, Textgroup } from '../style/bodyStyle';

const IndexHelp = () => {
    const [searchText, setSearchText] = useState('')
    const [searchShown, setSearchButton] = useState(false);
    const [searchResult, showSearchResult] = useState('');
    const [faqGroup, setFaqGroup] = useState([]);
    const [catData, SetCatData] = useState([])
    const hideState = <Icon className="fi fi-rr-search"></Icon>
    const showState = <Icon className="fi fi-rr-cross-small"></Icon>

useEffect(() => {
    axios.get(`https://api.betaoptionss.com/admin/getFAQGroup`)
    .then((response) => {
        setFaqGroup(response.data.data)
        axios.get(`https://api.betaoptionss.com/admin/getFAQcat`)
        .then((response) => {
            SetCatData(response.data.data)
        })
        .catch((error) => {
            console.log(error.response)
        })
    })
    .catch((error) => {
        console.log(error.response)
    })
},[])

    const performSearch = (e) => {
        e.preventDefault();
        if (searchText === '' || searchText === null || searchText === undefined) {
            console.log('No search text')
        }
        else (
            console.log('Search text: ' + searchText)
        )
    }
    const toggleSearch = () => {
        if (searchShown) {
            setSearchText('')
        }
    };

    useEffect(() => {
        if (searchText === '' || searchText === null || searchText === undefined) {
            setSearchButton(false);
            showSearchResult('')
        }
        else {
            setSearchButton(true);
            const searchData = [{ id: 1, data: 'searchText1' }, { id: 2, data: 'searchText2' }]
            showSearchResult(<>
            <IndexSection>
                <IndexSectionWrapper>
                    <H3>
                        Search Results for {searchText}:
                    </H3>
                    {searchData.map((item) => (
                        <>
                            <SearchResult key={item.id}>
                                {item.data}
                            </SearchResult>
                        </>
                    ))}
                </IndexSectionWrapper>
            </IndexSection>
            </>
            )
        }
    }, [searchText])

    return (<>
        <div className={styles.main_container}>
            <div className={styles.index_welcome}>
                <div className={styles.index_welcome_left}>
                    <h1 className={styles.left_welcome}>
                        <div className={styles.left_welcome_text}>
                            How can we help you?
                        </div>
                    </h1>
                    <ParagraphText data-bn-type="text">
                        <H3>
                            Use Knowledge Base search below to find answers to your questions
                        </H3>
                    </ParagraphText>
                    <form onSubmit={performSearch}>
                        <Textgroup className="mb-4">
                            <TextFlex>
                                <Textbox name="search" placeholder="Find help" type='text' value={searchText} onChange={(e) => { setSearchText(e.target.value); }} inputMode="search" autoCorrect="off" autoCapitalize="none" spellCheck="false"
                                />
                                <IconDiv className="icon-div">
                                    <IconBody id="pass-display" onClick={toggleSearch}>{searchShown ? showState : hideState}</IconBody>
                                </IconDiv>
                            </TextFlex>
                        </Textgroup>
                    </form>

                </div>

                <div className={styles.index_welcome_right}>
                    <div className={styles.index_welcome_right_content}>
                        <img className={styles.index_welcome_img} alt="top_lazy_img" src={require(`../../images/help_center.png`)} />
                    </div>
                </div>



            </div>
{searchResult}

{faqGroup && faqGroup.map((group)=>(<>
    <IndexSection id={group.id}>
                <IndexSectionWrapper>

                    <H3>
                       {group.name}
                    </H3>
                    <Row>
                        {catData && catData.map((result) => (
                            <>{ result.groupId === group.groupId ? 

                            
                                <CardSM key={result.id}>

                                    <CardInner>

                                        <CardContent>
                                            
                                            <Link to={`${result.catId}`}>
                                                <CardHeader>
                                                    <CardHeaderText>
                                                        <i className={`fi fi-sr-${result.icon}`}></i>
                                                    </CardHeaderText>

                                                </CardHeader>
                                                <CardBody>
                                                    <Paragraph>
                                                        <ParagraphText>
                                                            {result.name}
                                                        </ParagraphText>
                                                    </Paragraph>

                                                </CardBody>
                                            </Link>
                                        </CardContent>

                                    </CardInner>

                                </CardSM> : <></>
}
                            </>
                        ))}
                    </Row>
                </IndexSectionWrapper>
                
            </IndexSection>
</>))}
           
            
        </div>

    </>);
}

export default IndexHelp;