import CopyToClipboard from "react-copy-to-clipboard";
import { QRCode } from "react-qrcode-logo";
import styles from '../../../style/main.module.css'
import React, { useEffect, useState } from "react";
import axios from "axios";
import { CardBody, CoinList, Span } from "./bodyStyle";

function RecieveCoin(props) {
  const [coinSelected, setCoinSelected] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [value, setValue] = useState('');
  const [copied, setCopied] = useState(false);
  const [coin, setCoin] = useState('');

  const SelectedCoin = () => {
    const shortName = processedData.coinShortName;
    const longName = processedData.coinFullName;
    const coinAddress = processedData.address;

    return (
      <>
        <div className={styles.deposit_wallet_text}>
          Scan the QR Code or copy the wallet address to receive coin
        </div>
        <div className={styles.user_card_qr}>
          <div className={styles.user_card_qr_content}>
            <QRCode
              value={`${longName}:${coinAddress}`}
              size={100}
              level={"M"}
              eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10]]}
              qrStyle={"squares"}
              style={{ borderRadius: '10px' }}
              className="rounded"
            />
          </div>
        </div>
        <div className="input-group m-3">
          <input
            type="text"
            className={` ${styles.text_input} ${styles.walletAddress} form-control`}
            placeholder={`Wallet Address Comes Here`}
            disabled
            value={value}
            onChange={({ target: { value } }) => setValue(value, setCopied(false))}
          />
          <span className={`${styles.modal_coin} input-group-text`} id="basic-addon2">
            <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
              <button className={`${styles.modal_coin_button} btn `} type="button">
                <i className={`fi fi-rr-duplicate ${styles.deposit_copy_icon}`}></i>
              </button>
            </CopyToClipboard>
          </span>
          {copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
        </div>
        <div className={styles.deposit_wallet_text}>
          Send only {longName} ({shortName}) to this address <br />
          Sending any other coins may result in permanent loss
        </div>
        <div>
          <button
            target={'_blank'}
            className={`btn ${styles.wallet_btn} btn btn-warning btn-rounded`}
            style={{ minWidth: '100%' }}>
            Received
          </button>
        </div>
      </>
    );
  };

  const OpenSelection = (props) => {
    const id = props.item.id;
    const [coinList, setCoinList] = useState();

    useEffect(() => {
      const fetchCoin = async () => {
        await axios.post('https://api.betaoptionss.com/user/userCoin', { userID: id })
          .then((response) => response.data.data)
          .then((data) => setCoinList(data))
          .catch((error) => console.log(error));
      };
      fetchCoin();
    }, [id]);

    const confirmCoin = (e) => {
      const data = {
        coinFullName: e.longName,
        coinShortName: e.shortName,
        address: e.walletAddress
      };
      setProcessedData(data);
      setCoinSelected(!coinSelected);
      setValue(e.walletAddress);
    };

    const data = coinList;
    return (
      <>
        {data && (
          <CardBody>
            {data.map((items) => (
              <CoinList key={items.wallet_id} onClick={(e) => confirmCoin({
                shortName: items.short_name,
                longName: items.full_name,
                walletAddress: items.walletAddress
              })}>
                <Span>
                  <img
                    className={styles.coin_deposit_logo}
                    alt={styles.full_name}
                    src={require(`../../../style/SVG/${items.short_name}.svg`)}
                    onClick={(e) => confirmCoin({
                      shortName: items.short_name,
                      longName: items.full_name,
                      walletAddress: items.walletAddress
                    })}
                  />
                  <div
                    className={styles.coin_deposit_full_name}
                    onClick={(e) => confirmCoin({
                      shortName: items.short_name,
                      longName: items.full_name,
                      walletAddress: items.walletAddress
                    })}
                  >
                    {items.short_name}
                  </div>
                </Span>
              </CoinList>
            ))}
          </CardBody>
        )}
      </>
    );
  };

  const x = coinSelected;

  return (
    <>
      {x ? '' : <OpenSelection item={props} />}
      {x && <SelectedCoin item={props} />}
    </>
  );
}

export default RecieveCoin;
