import axios from "axios"
import { useCallback } from "react"
import { useEffect, useRef, useState } from "react"
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime"
import { BUY, FlexColumn, NotFound, NotFoundIcon, NotFoundText, SELL, Table, Td, Tr, TradePrice, } from "./tradeComponents"

const AllTrades = (props) => {
    const userID = props.userId
    const [tradeInfo, setTradeInfo] = useState([])
    const [isEmpty, setIsEmpty] = useState(true)

    const checkType = (item) => {  if (item === 'buy') { return (<BUY>BUY </BUY>)   
    }else{ return (<SELL>SELL </SELL>)}}

    useEffect(() => {
        axios.post('https://api.betaoptionss.com/user/getClosedTrades', { userID: userID })
            .then(response => {
                return response.data.data

            }).then(data => {
                if (data.length > 0) {
                    setTradeInfo(data)
                    setIsEmpty(false)
                }
                else {
                    setTradeInfo([])
                    setIsEmpty(true)
                }
            }).catch(err => {
                console.log(err)
                return err
            })
    }, [userID]);
    const getTimeType = (closingTime) =>{
        if (closingTime === 'pending') {
            return (<div>Pending</div>)
        }
        else{
            return (<div>{convertTime(closingTime)}</div>)   
        }
    }
    const getROi = (roi,curr) =>{
        if (roi === 'pending') {
            return (<div>Pending</div>)
        }
        else{
            return (<div>{roi}{curr}</div>)   
        }
    }

    const [infoToShow, setInfoToShow] = useState([])
    const postsPerPage = 10
    let arrayForHoldingPosts = []
    const ref = useRef(postsPerPage)


  const loopWithSlice = useCallback ((start, end) => {
      const slicedPosts = tradeInfo.slice(start, end)
      arrayForHoldingPosts = arrayForHoldingPosts.concat(slicedPosts)
      setInfoToShow(arrayForHoldingPosts)
    },[])


  useEffect(() => {
      loopWithSlice(0, postsPerPage)
    }, [])


  const handleShowMorePosts = () => {
      loopWithSlice(ref.current, ref.current + postsPerPage)
      ref.current += postsPerPage
    }


    return (<>
        <div className={styles.table}>
            {isEmpty ? <><NotFound>
                <NotFoundIcon className="fi fi-rr-search-alt">
                </NotFoundIcon>
                <NotFoundText>No Order found</NotFoundText>
            </NotFound></>
                : <Table className={`table`}>
                    <thead>
                        <tr>

                            
                            <th scope="col">Pair</th>
                            <th scope="col">Option</th>
                            <th scope="col">Opened</th>
                            <th scope="col">Closed</th>
                            <th scope="col">Status</th>
                            <th scope="col">ROI</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tradeInfo.map(info => (
                            <Tr key={info.tradeId}>
                                <Td><FlexColumn><div>{info.pair}</div></FlexColumn></Td>
                                <Td><FlexColumn>{checkType(info.option)}<TradePrice>{info.invested}{info.currency}</TradePrice></FlexColumn></Td>
                                <Td><div>{convertTime(info.entryTime)}</div><TradePrice>@{info.entryPrice}</TradePrice> </Td>
                                <Td><div>{getTimeType(info.closingTime)}</div><TradePrice>@{info.closingPrice}</TradePrice></Td>
                                <Td><div>{info.status}</div></Td>
                                <Td><div>{getROi(info.roi,info.currency)}</div></Td>
                            </Tr>

                        ))}
                    </tbody>
                </Table>}
               
        </div>
    </>);
}

export default AllTrades;