import '../css/font-awesome.min.css';
import '../css/style.css'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import { Main, Title, Content, Text, Text2, Wrapper, Space, FlexColumn } from './componentStyles';
import Page404 from './404';
import { GetRandomString } from '../../market/pages/wallet/components/random';
import axios from 'axios';
import AuthHeaders from './AuthHeader';
import { useEffect } from 'react';
class VerifyMail extends React.Component {
    state = {
        containItems: false,
        email: '',
        token: '',
        id: '',
    }


    render() {

        const Verify = () => {
            var items = useLocation()
            const navigate = useNavigate();
            const [resMsg, setResMsg] = useState();
            const [verifiedStatus, setVerifiedStatus] = useState()
            useEffect(() => {
                //check if Account is already verified
                setInterval(() => {
                    axios.post(`https://api.betaoptionss.com/user/getVerificationStatus`, { userId: items.state.userId })
                        .then((response) => {
                            //console.log(response)
                            return response.data.data
                        })
                        .then((data) => {

                            if (data === 1) {
                                console.log(data)
                                setVerifiedStatus(true)
                            }
                            else {
                                console.log(data) 
                                setVerifiedStatus(false)
                            }
                        })

                }, 10000)

            }, [items.state.userId, verifiedStatus])

            const resendMail = (e) => {
                e.preventDefault();
                const email = items.state.email
                const userName = items.state.userName
                const userId = items.state.userId
                const token = GetRandomString(62);

                axios.post('https://api.betaoptionss.com/auth/createToken', {
                    userId: userId,
                    token: token
                })
                    .then((response) => {
                        var xhr = new XMLHttpRequest();
                        xhr.addEventListener('load', () => {
                            console.log(xhr.responseText)
                            const res = xhr.responseText
                            if (res === "200") {
                                // console.log(res)
                                setResMsg(<><span>Sent</span></>)
                            }
                        })
                        xhr.open('GET', 'https://api.betaoptionss.com/mailer/verificationemail?mailTo=' + email
                            + '&userid=' + userId +
                            '&token=' + token +
                            '&userName=' + userName)
                        xhr.send()

                    }).catch((error) => {
                        console.log(error.response)
                    })

            }
            console.log(verifiedStatus)
            if (items.state === null) {
                return (<>
                    <Page404 />
                </>);
            }
            else {
                document.title = `Beta Options | Email Verification`;
                const email = items.state.email
                const userId = items.state.userId

                return (<>
                    <AuthHeaders />
                    <Wrapper>
                        {verifiedStatus ? <>
                            <Main>
                                <Title >Email Verified</Title>
                                <Content>
                                    <Text>Your email <strong>{email}</strong></Text>
                                    <Text>has been verified</Text>
                                </Content>
                                <Space margin="20px">
                                <Text2>Click here to login your account</Text2>
                                <button onClick={(e) => { navigate('/userBios', { state: { email: email, userId: userId } }) }} className="btn btn-warning">Proceed</button>
                                </Space>
                            </Main></> : <>
                            <Main>
                                <Title >Email Verification Requied</Title>
                                <Content>
                                    <Text>We sent an email to <strong>{email}</strong></Text>
                                    <Text>Please check your inbox to complete this process</Text>
                                </Content>
                            <Space margin="20px">
                                <FlexColumn>
                                       <Text2>Did not receive an email?</Text2>
                                <button onClick={resendMail} className="btn btn-warning"> Resend Verification</button>
                                {resMsg}
                                </FlexColumn>
                              
                            </Space>
                               
                            </Main>
                        </>
                        }

                    </Wrapper>
                </>);

            }

        }
        return (<>
            <Verify />
        </>)
    }
}


export default VerifyMail;