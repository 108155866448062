import './css/font-awesome.min.css';
import './css/style.css'
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from 'axios';
import { GetRandomString } from '../market/pages/wallet/components/random';
import  './css/loadProgressMin.css'
import React  from 'react';
import AuthHeaders from './components/AuthHeader';
import { AuthContainerAlt, AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, Wrapper } from './components/componentStyles';

const Register = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const ref = urlParams.get('ref')

	document.title = `Beta Options | Registration`;
    const d = new Date();
    let year = d.getFullYear();
    const [email, setEmail]= useState('');
	const [regBtn, setRegBtn]= useState(<button className="btn btn-warning" type="submit">Create Account</button>);
	const [regResponse, setRegResponse] = useState('');
	const navigate = useNavigate();

	

    const registerUser = (e)=> {
        e.preventDefault();
		setRegBtn(<button className="btn btn-warning" type="submit" disabled={true}>
					<div className="lds-ring"><div></div><div></div><div></div><div></div></div></button>)

       const userId = GetRandomString(24)
	   const payload = {
		userId: userId,
		email:email,
		refBy:ref
	}
	const refPayload={
		userId: userId,
		refBy: ref,
		refId:GetRandomString(24),
		amount:"50",
		date: Math.floor(Date.now() / 1000)
	}

	// console.log(refPayload)
	if (ref !== null) {
				axios.post('https://api.betaoptionss.com/auth/regRefferal',refPayload)
	}

        axios.post('https://api.betaoptionss.com/auth/register',payload).then((response)=>{
			const token = GetRandomString(62)
			const time = Math.floor(Date.now() / 1000)
			const expires = Math.floor(time + 172800)  //2 days
			const status = "pending"
			axios.post('https://api.betaoptionss.com/auth/createToken',{
			userId: userId,
            token: token,
			time: time,
			expires: expires,
			status: status,
			
				}) 
			.then((response)=>{

				var xhr = new XMLHttpRequest();
				xhr.addEventListener('load', ()=>{
					//console.log(xhr.responseText)
					const res =xhr.responseText
					if (res === "200"){
						
						navigate('/verify', {state: {email:email, userId:userId, token:token}})
					}
				})
				xhr.open('GET', 'https://api.betaoptionss.com/mailer/verificationemail?mailTo=' + email 
				+'&userid=' + userId + 
				'&token=' + token)
				xhr.send()
				
			})
			
  
        }).catch((error)=>{
			const message = error.response.data
			setRegBtn(<button className="btn btn-warning" type="submit">Create Account</button>)
			message.forEach(item => {
				setRegResponse(<div className='alert alert-danger p-2 alert-dismissible fade show' role="alert">{item}</div>);

			});
			
           
        })
    }

    return ( 
        
        <>
<AuthHeaders/>
 <Wrapper>
        <div className="container-fluid user-auth row">
			<AuthContainerAlt>
				<div className="form-container">
					<FlexColumn>
						
						<div className="text-center">
							<h2 className="title-head hidden-xs">get <span className='text-warning'>started</span></h2>
							 <p className="info-form">Open an account for free and start trading now!</p>
						</div>
						
						<form onSubmit={registerUser}>
						<div className='mt-10'>{regResponse}</div>

							<AuthTextgroup className="mb-4">
								<AuthTextFlex>
								<AuthTextbox placeholder="EMAIL" type="email" required value={email} onChange={(e)=> setEmail(e.target.value)}/>
							</AuthTextFlex>
							</AuthTextgroup>
							
                            <FlexColumn >

								{regBtn}
								<p className="text-center"><Link to="/" className=' text-warning'>Back to Home</Link> | already have an account ? <Link to="/login" className=' text-warning'>Login Instead</Link>  </p>
								<br/>
							</FlexColumn>
						</form>
						
						{/* Form Ends  */}
					</FlexColumn>
					
				</div>
				
				{/* Copyright Text Starts  */}
				<p className="text-center copyright-text">Copyright © {year} Beta Options All Rights Reserved</p>
				{/* Copyright Text Ends  */}
			</AuthContainerAlt>
		</div>
	

    </Wrapper>
        </>
     );
}
 
export default Register;