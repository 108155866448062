import { Link, useParams } from 'react-router-dom';
import styles from '../css/index.module.css'
import { useEffect, useState } from 'react';
import { CardBody, CardContent, CardHeader, CardInner, CardLG, Column, H3, IndexSectionHelpSingle, IndexSectionWrapper, Paragraph, ParagraphText, Text, Text2, TextIcon, TextIconWrapper } from '../style/bodyStyle';
import axios from 'axios';
const HelpCategory = () => {
    const params = useParams()
    const id = params.id
    const [questions, setQuestions] = useState([])
    const [count, setCount] = useState('')
    const [catName, setCatName] = useState('')
    const [faqInfo, setFaqinfo] = useState()
    useEffect(() => {
        axios.get(`https://api.betaoptionss.com/admin/getFAQ?catId=${id}`)
            .then((response) => {
                setQuestions(response.data.data)
                setCount(response.data.data.length)
                setCatName(response.data.catInfo.name)
            })
    }, [id])
    return (<>

        <div className={styles.main_container}>
            <IndexSectionHelpSingle >
                <IndexSectionWrapper>
                    <Column>
                        <TextIconWrapper>
                            <Link to="/web/help">
                                <TextIcon className="fi fi-rr-arrow-small-left"></TextIcon>
                                <Text2>
                                    Help Center
                                </Text2>
                            </Link>
                        </TextIconWrapper>
                        <Column>
                            <H3>{catName}</H3>
                            <Text2>
                                Topics under {catName}: {count}
                            </Text2>
                            {
                                questions && questions.map((info) => (
                                    <>
                                        <CardLG key={info.id}>
                                            <CardInner>
                                                <CardContent>
                                                    <CardBody>
                                                        <Link to={'#'} data-bs-toggle="modal" data-bs-target="#helpModal" onClick={(e) => { setFaqinfo(info) }}>
                                                            <Paragraph>
                                                                <ParagraphText>
                                                                    {info.question}
                                                                </ParagraphText>
                                                                <Text >
                                                                    {(info.answer).substring(0, 130)}...
                                                                </Text>
                                                            </Paragraph>
                                                        </Link>
                                                    </CardBody>
                                                </CardContent>
                                            </CardInner>
                                        </CardLG>
                                    </>
                                ))
                            }

                        </Column>




                    </Column>
                </IndexSectionWrapper>
            </IndexSectionHelpSingle>

        </div>
        <div className="modal fade" id="helpModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog modal-lg">
                <div className={`  ${styles.card_inner} modal-content `}>
                    <div className={`${styles.card_header} modal-header`}>
                        <div className={`  ${styles.card_header_text}`} id="helpModalLabel"></div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className={`${styles.card_body} modal-body`}>

                        <CardContent>
                            <CardHeader>
                                <CardContent>
                                    <CardBody>
                                        <Paragraph>
                                            <ParagraphText>
                                                {faqInfo && faqInfo.question}
                                            </ParagraphText>
                                            <Text >
                                                {faqInfo && faqInfo.answer}
                                            </Text>
                                        </Paragraph>

                                    </CardBody>
                                </CardContent>
                            </CardHeader>
                        </CardContent>

                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default HelpCategory;