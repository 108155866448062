import axios from 'axios';
import { useEffect, useState } from 'react';
import Skeleton from '../skeleton/skeleton';
import ExchangeList from './exchangeList';

const PairList = (props) => {
    // const currency = props.currency
    const symbol = props.currencySym
    const [isLoading, setIsLoading] = useState(true) 
const [coinMarket, setCoinMarket]= useState([])

    useEffect(() => {
        const AcutalRate = async () => {
           
      var  pairData =[]
    const userFiat = JSON.parse(localStorage.getItem('OMS__CUR'))
    const pairs = [{ base: "BTC", pair: "USD" },
    { base: "BNB", pair: "USD" },
    { base: "BUSD", pair: "USD" },
    { base: "ETH", pair: "USD" },
    { base: "LTC", pair: "USD" },
    { base: "USDT", pair: "USD" }];
        for (let k = 0; k < pairs.length; k++) {
            const controller = new AbortController();
       await axios.post(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${pairs[k].base}&tsyms=${userFiat.name}`)
        .then(response =>{
            return response.data.RAW
        })
        .then(data=>{
          //  console.log(data)
            var item = JSON.stringify(data)
            if (String(pairs[k].base).length < 4){
                item = item.substring(14);
                const pairInfo =JSON.parse(item.slice(0, item.length - 2))
                const newPrice = JSON.stringify(pairInfo.PRICE)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|,/gm, '');
               const result = Number(strip).toFixed(2)
               pairData.push({"price": result, "change": Number(pairInfo.CHANGEPCT24HOUR).toFixed(2), "base": pairs[k].base, "cross":userFiat.name,  "symbol": userFiat.symbol, "marketCap": pairInfo.MKTCAP} )
               
                

           }
           else if (String(pairs[k].base).length === 4){
            item = item.substring(15);
            const pairInfo =JSON.parse(item.slice(0, item.length - 2))
            const newPrice = JSON.stringify(pairInfo.PRICE)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|,/gm, '');
               const result = Number(strip).toFixed(2)
                pairData.push({"price": result, "change":  Number(pairInfo.CHANGEPCT24HOUR).toFixed(2), "base": pairs[k].base, "cross":userFiat.name,  "symbol": userFiat.symbol,  "marketCap": pairInfo.MKTCAP} )

       }
        })
        controller.abort()
    }
   

   setCoinMarket(pairData)
    localStorage.setItem('__cList', JSON.stringify(pairData))
    // console.log(pairData)
    setIsLoading(false)
}

AcutalRate()
window.setInterval(() => {
            AcutalRate()
            }, 30000)
      },[symbol])



// useEffect (() => {
//     axios.post(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${currency}`)
//     .then((response) => {
//         return response.data
//     })
//     .then((data) => {
//         const price = data
//         const newPrice = JSON.stringify(price)
//         let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
//         const result = Number(strip).toFixed(2)
//         setFiatEquiv(result)
//     })
// },[currency])


// useEffect(() => {
//     const closingPrices = []
//     // let newP = ""
//     // let currencyEqual = ""

//     var d = Math.round(new Date().getTime() / 1000);
//     var yesterday = d - (24 * 3600);
//     const pairs = [{ base: "BTC", pair: "USD" },
//     { base: "BNB", pair: "USD" },
//     { base: "BUSD", pair: "USD" },
//     { base: "ETH", pair: "USD" },
//     { base: "LTC", pair: "USD" },
//     { base: "USDT", pair: "USD" }];
//         for (let k = 0; k < pairs.length; k++) {
//             axios.post(`https://min-api.cryptocompare.com/data/pricehistorical?fsym=${pairs[k].base}&tsyms=USD&ts=${yesterday}`)
//             .then((response) => {
//                 setIsLoading(true);
//                 return response.data
                
//             })
//             .then((data) => {
//                 const price = data
//                 const newPrice = JSON.stringify(price)
//                 let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
//                 // const result = Number(strip).toFixed(2)
//                 // setFiatEquiv(result)
//                 closingPrices.push({'price':strip,
//                                     'pair':pairs[k].base})
//                  localStorage.setItem(`__changes`, JSON.stringify(closingPrices))
//                  setIsLoading(false)
//             })                 
//         }
//     },[])

// useEffect(() => {
//     const pairs = [{ base: "BTC", pair: "USD" },
//     { base: "BNB", pair: "USD" },
//     { base: "BUSD", pair: "USD" },
//     { base: "ETH", pair: "USD" },
//     { base: "LTC", pair: "USD" },
//     { base: "USDT", pair: "USD" }];

//     const AcutalRate = async () => {
//         const NewPrices = []
//         let newP = ""
//         let currencyEqual = ""
//         for (var i = 0; i < pairs.length; i++) {
//             await axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${pairs[i].base}&tsyms=USD`)
//                 .then(response => {
                   
//                     return response.data;
//                 })
//                 .then(data => {
//                     const price = data
//                     const newPrice = JSON.stringify(price)
//                     let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
//                     const result = Number(strip).toFixed(2)
//                     let c = result
//                     newP = c
//                     currencyEqual = (c * FiatEquiv).toFixed(2)
//                 })
//             const PairInfo = {
//                 base: pairs[i].base,
//                 pair: pairs[i].pair,
//                 price: newP,
//                 fiatCurrency: currency,
//                 fiatSymbol: symbol,
//                 fiatEquiv: currencyEqual
//             }

//             NewPrices.push(PairInfo);

//         }
//         setList(NewPrices)
       
//     }
//     AcutalRate()

//     window.setInterval(() => {
//         AcutalRate()
        
//         }, 30000)
        
// }, [currency, symbol, FiatEquiv])
  


    return (<>
    {isLoading ? <Skeleton type="PairList"/> : coinMarket && <ExchangeList List={coinMarket} /> } 

    </>);
}

export default PairList;