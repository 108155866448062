import './css/font-awesome.min.css';
import './css/style.css';
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from 'axios';
import React  from 'react'; 
import AuthHeaders from './components/AuthHeader';
import { AuthContainerAlt, AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, Wrapper } from './components/componentStyles';

const Forgot = () => {
    const d = new Date();
    let year = d.getFullYear();

    const [email, setEmail] = useState('');
	const [resetResponse, setResponse] = useState()
    const resetPassword = (e) => {
        e.preventDefault();
        axios.post('https://api.betaoptionss.com/auth/passwordReset',{
           email: email
        }).then((response)=>{
                console.log(response)
				setResponse(<>Reset link Sent to your email</>)
        }).catch((error)=>{
            console.log(error)

        })
    }

    return ( 
<>
<AuthHeaders/>
    <Wrapper>
        <div className="container-fluid user-auth row">
			<AuthContainerAlt>
			
				<div className="form-container">
					<FlexColumn>
						<div className="row text-center">
							<h2 className="title-head hidden-xs">Forgot <span className='text-warning'>Password</span></h2>
							 <p className="info-form">Enter Your user Email to reset your password</p>
						</div>
						
						<form onSubmit={resetPassword}>
						<AuthTextgroup className="mb-4">
								<AuthTextFlex>
								<AuthTextbox  name="email" id="txt_uname" placeholder="EMAIL" type="email" value={email} onChange={(e)=> setEmail(e.target.value)} required />
							</AuthTextFlex>
							</AuthTextgroup>
						
							<FlexColumn>
								<button className="btn btn-warning" name="reset" type="submit">Reset</button>
								<p className="text-center " ><Link to="/login" className=' text-warning'>Login</Link> | don't have an account ? <Link to="/register" className=' text-warning'>Register Now</Link></p>
							</FlexColumn>
								<div className='mt-10'>{resetResponse}</div>
						</form>
					
					</FlexColumn>
				</div>
				
				<p className="text-center copyright-text">Copyright © {year} Beta Options All Rights Reserved</p>
				
			</AuthContainerAlt>
		</div>
      

    </Wrapper>
	{/* Wrapper Ends  */}



</>

     );
}
 
export default Forgot;