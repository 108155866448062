import styles from '../../../style/main.module.css'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CurrencyFormat from 'react-currency-format'
import '../../../../auth/css/loadProgressMin.css'
import { GetRandomString } from '../components/random'


const ProccesedData = ({ data }) => {
    const processed = data
    const fee = Number(processed.equv * 0.05)
    const totalC = Number(processed.equv) + fee
    return (
        <>
            <div className={styles.card_list_ctr_title}>
                Coin Withdrawal Pending
            </div>
            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                Withdrawal from {processed.coinFull} ({processed.coinType})
            </div>
            <div className={styles.payment_page_title}>
                Withdrawal Amount:
                <div className={styles.payment_page_amount}>
                    {processed.equv}{processed.coinType}
                    <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                        =
                        <CurrencyFormat value={processed.amount} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                            isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />

                    </div>
                </div>
            </div>
            <div className={styles.payment_page_title}>
                Withdrawal Fee:
                <div className={styles.payment_page_amount}>
                    {fee.toFixed(8)}{processed.coinType}
                    <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                        (0.5%)
                    </div>
                </div>

            </div>
            <div className={styles.payment_page_title}>
                Total Amount:
                <div className={styles.payment_page_amount}>
                    {totalC.toFixed(8)}{processed.coinType}
                </div>
            </div>
            <div className={styles.deposit_wallet_text}>
                Your Withdrawal Request of <span style={{ color: 'var(--warning)' }}>{processed.equv}{processed.coinType}</span> to <span style={{ color: 'var(--warning)' }}>{processed.toWalletAddress}</span>  has been sent to the Blockchain.
            </div>

        </>
    )
}
const WithdrawCoin = () => {
    const userCurrency = JSON.parse(localStorage.getItem('OMS__CUR'))
    const Fiat = userCurrency.name
    const currSym = userCurrency.symbol
    const userId = JSON.parse(localStorage.getItem('_currentInfo')).user_id
    const userEmail = JSON.parse(localStorage.getItem('_currentInfo')).email
    const [coinEquv, setCoinEquv] = useState(0)
    const [mainCur, setMainCur] = useState(0)
    const [proceedBtn, setProceedBtn] = useState('Proceed')
    const [transWalletAddress, setTransWalletAddress] = useState('')
    const [coinList, setCoinList] = useState("");
    const [walletAddress, setCoinWallet] = useState("");
    const [walletId, setWalletId] = useState("");
    const [coinFullName, setCoinFullname] = useState('bitcoin');
    const [coinShortName, setCoinShortname] = useState('BTC');
    const [coinFromBalance, setCoinFromBalance] = useState('0.00000000')

    const getEquiv = (e) => {
        e.preventDefault();
        const init = Number(e.target.value)
        axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${Fiat}&tsyms=${coinShortName}`)
            .then((response) => {

                const price = response.data
                const newPrice = JSON.stringify(price)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
                const newCryptoRate = (init * Number(strip)).toFixed(8)
                setMainCur(init)
                setCoinEquv(newCryptoRate)
            }).catch((error) => {
                console.log(error)
            })
    }

    // const data = coinList
 
    useEffect(() => {
        const fetchCoin = async () => {
            await axios.post('https://api.betaoptionss.com/user/userCoin', { userID: userId })
                .then((response) => {
                    return response.data.data;
                }).then((data) => {
                    setCoinList(data);
                    setCoinWallet(data[0].walletAddress);
                    setWalletId(data[0].wallet_id);
                    setCoinShortname(data[0].short_name);
                    setCoinFullname(data[0].full_name);
                    setCoinFromBalance(data[0].balance)
                }).catch((error) => {
                    console.log(error);
                });
        };

        fetchCoin();
    }, [userId]);

    const GenerateTransaction = (e) => {
        e.preventDefault();
        if (Number(coinEquv) >= Number(coinFromBalance)) {
            alert('Insufficient Balance')
            return
        }
        else if (transWalletAddress === '') {
            alert('Please enter a valid wallet address')
            return
        }
        else if (coinEquv === 0) {
            alert('Please enter a valid amount')
            return
        }
        const t_id = GetRandomString(16)
        const trans_data = {
            trans_id: t_id,
            amount: mainCur,
            equv: coinEquv,
            coinType: coinShortName,
            fiat: Fiat,
            userId: userId,
            userEmail:userEmail,
            syb: currSym,
            walletId: walletId,
            wallet: walletAddress,
            coinFull: coinFullName,
            openDate: Math.floor(Date.now() / 1000),
            closeDate: Math.floor((Date.now() / 1000) + 600),
            toWalletAddress: transWalletAddress
        }
       // console.log(trans_data)
        setProceedBtn(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>)

        axios.post('https://api.betaoptionss.com/user/createTransaction',
            {
                trans_id: t_id,
                amount: mainCur,
                equv: '-'+coinEquv,
                coinType: coinShortName,
                fiat: Fiat,
                userId: userId,
                trans_type: 'withdraw',
                date: Math.floor(Date.now() / 1000),
                status: "pending",
                walletId: walletId
            })
            .then((response) => { 
                axios.post('https://api.betaoptionss.com/mailer/withdrawNotification',{ trans_id: t_id})

                return response.data
               
            }).then((data) => {
                setShowProcessed(!showProcessed)
                setProcessedData(<><ProccesedData data={trans_data} /></>)

            }).catch((error) => {
                console.log(error)
            })
    }
    const [proccesed, setProcessedData] = useState()
    const [showProcessed, setShowProcessed] = useState(false)
    return (<>{
        showProcessed ? proccesed :
            <form onSubmit={GenerateTransaction}>

                <div className={`${styles.flex_column} input-group mb-3 `}>
                    <div className={`${styles.flex_row} ${styles.swap_coin_input}`}>
                        <input type="text" className={` ${styles.text_input} form-control`} placeholder={`Enter Amount in ${Fiat}`} name="txtbitamount" onChange={getEquiv} />
                        <span className={`${styles.modal_coin} input-group-text`} id="basic-addon2">
                            <div className="dropdown">
                                <button
                                    className={`${styles.modal_coin_button} btn dropdown-toggle`}
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img className={styles.coin_table_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${coinShortName}.svg`)} />
                                </button>
                                {coinList && (
                                    <ul className={`dropdown-menu ${styles.dropdown_m} `} aria-labelledby="dropdownMenuButton1">
                                        {coinList.map((items) => (
                                            <li key={items.wallet_id}>
                                                <div className={` ${styles.dropdown_z} dropdown-item`} id={items.short_name} onClick={(e) => {
                                                    setCoinShortname(items.short_name);
                                                    setCoinWallet(items.walletAddress);
                                                    setWalletId(items.wallet_id);
                                                    setCoinFromBalance(items.balance)
                                                }}>
                                                    <img className={styles.coin_deposit_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${items.short_name}.svg`)} onClick={(e) => {
                                                        setCoinShortname(items.short_name);
                                                        setCoinWallet(items.walletAddress);
                                                        setWalletId(items.wallet_id);
                                                        setCoinFromBalance(items.balance)
                                                    }} />
                                                    <div className={styles.coin_deposit_coin_name} onClick={(e) => {
                                                        setCoinShortname(items.short_name);
                                                        setCoinWallet(items.walletAddress);
                                                        setWalletId(items.wallet_id);
                                                        setCoinFromBalance(items.balance)
                                                    }}>{items.short_name}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </span>
                       
                    </div>
                        <div className={styles.swap_coin_text_balance}>
                            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                Balance: {coinFromBalance}{coinShortName}
                            </div>
                        </div>
                </div>
                <div className="input-group mb-3">
                    <div className={styles.swap_coin_input}>
                        <input type="text" className={` ${styles.text_input} form-control`} placeholder={`Enter ${coinShortName} Wallet Address`} name="walletAddress" onChange={(e) => setTransWalletAddress(e.target.value)} />
                    </div>

                </div>
                <div className={styles.coin_deposit_base}>
                    <button className="btn btn-success" style={{ minWidth: '100%' }}>{proceedBtn}</button>
                    <div className={`ms-auto ${styles.deposit_equivalent}`}>
                        <CurrencyFormat value={mainCur} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                            isNumericString={true} thousandSeparator={true} prefix={`${currSym}`} />
                        <i className={`fi fi-rr-exchange ${styles.deposit_exchanger_icon}`}></i>{coinEquv} {coinShortName}
                    </div>

                </div>

            </form>
    }
    </>);
}

export default WithdrawCoin;