import axios from 'axios';
import wall from '../../style/new.module.css'
import TraderSummary from '../trade/traderSummary';
import { Suspense, useEffect } from 'react';
import { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
const GoalMeter = ({userId, currency, currencySymbols, balance}) => {
    useEffect(() => {
        axios.post('https://api.betaoptionss.com/user/wonLoss', { userId: userId })
            .then(response => {
                // console.log(response)
                return response.data
            }).then(data => {
              
                
             setTotalTrades(data.won.length + data.loss.length+data.pending.length)
             const t = data.won.length + data.loss.length + data.pending.length
            const ratioWon = (data.won.length / t) * 100
            const ratioLoss = (data.loss.length / t) * 100
            if (data.won.length > 0){
                 setTotalWon(ratioWon.toFixed(2))
                setTotalLoss(ratioLoss.toFixed(2))
            }
            else{
                setTotalWon(0)
                setTotalLoss(0)
            }
            setWon(data.won.length)
            // setTotalPending(data.pending.length)
              
            })
    }, [userId]);

   const [won, setWon]=useState()
    const [totalTrades, setTotalTrades] = useState()
    const [totalWon, setTotalWon] = useState()
    const [totalLoss, setTotalLoss] = useState()
    // const [totalPending, setTotalPending] = useState()


    return (<>
        <div className={wall.p_5}>
            <div className={`${wall.flex} ${wall.flex_wrap} ${wall.justify_around}`}>
                <div className={`${wall.well} ${wall.flex} col-md-4 col-12 ${wall.justify_center}`}>
                    <div className={`${wall.flex} ${wall.flex_col} ${wall.justify_between}`}>
                        <div className={wall.card_head_text}>Win Rate</div>
                        <div className={`${wall.pie} ${wall.animate}`} style={{ '--p': totalWon, '--c': 'lightgreen' }}> {totalWon}%</div>
                    </div>
                    <div className={`${wall.flex} ${wall.flex_col} ${wall.justify_between}`}>
                        <div className={wall.card_head_text}>Loss Rate</div>
                        <div className={`${wall.pie} ${wall.animate}`} style={{ '--p': totalLoss, '--c': 'red' }}> {totalLoss}%</div>
                    </div>
                </div>
                <div className={`${wall.well} col-md-4 col-12`}>
               <Suspense
               fallback="Loading...">
                <Progress  currency={currency} userId={userId} currencySymbols={currencySymbols} balance={balance} totalTrades={totalTrades} won={won}/>
                </Suspense> 
                </div>
                <div className={`${wall.well} col-md-3 col-12`}>
                    <div className={`${wall.flex} ${wall.flex_col}`}>
                        <div className={wall.card_head_text}>Statistics</div>
                        <div className={`${wall.flex} ${wall.flex_col}`}>
                            <TraderSummary currency={currency} userId={userId} />
                         
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>);
}

export default GoalMeter;

const Progress = ({currency, userId, currencySymbols, balance, totalTrades, won})=>{
const [activedays, setActivedays] = useState(0)
    
useEffect(()=>{
    
    axios.post('https://api.betaoptionss.com/user/getLogDate', {userId:userId})
    .then(res=>{
        setActivedays(res.data.items.daysPar)
    })
    .catch(err=>{
        console.log(err.response)
    })
},[userId])

    const depositRatio = (()=>{
        return (balance/100000)*100
    })

    return (<>
    <div className={`${wall.flex} ${wall.flex_col}`}>
                        <div className={wall.card_head_text}>Goal Meters</div>
                        <div className={`${wall.flex} ${wall.flex_col} ${wall.justify_between}`}>
                            <div className={`${wall.flex} ${wall.flex_col}`}>
                                <div className={`${wall.flex} ${wall.justify_between}`}><div>Total Trades</div><div> {totalTrades}/1000</div></div>
                                <div className={wall.progress}>
                                    <div className={`${wall.prograss_track} ${wall.animate2}`} style={{ '--progress': "#ffbd00", '--progress-width': (totalTrades/1000)*100 }}></div>
                                </div>
                            </div>
                            <div className={`${wall.flex} ${wall.flex_col}`}>
                                <div className={`${wall.flex} ${wall.justify_between}`}><div>Total Deposit</div><div>  
                                <CurrencyFormat value={balance} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                        isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} />/{currencySymbols}100,000.00</div></div>
                                <div className={wall.progress}>
                                    <div className={`${wall.prograss_track} ${wall.animate2}`} style={{ '--progress': "#90ee90", '--progress-width': depositRatio() }}></div>
                                </div>
                            </div>
                            <div className={`${wall.flex} ${wall.flex_col}`}>
                                <div className={`${wall.flex} ${wall.justify_between}`}><div>Total Wins</div><div>   {won}/1000</div></div>
                                <div className={wall.progress}>
                                    <div className={`${wall.prograss_track} ${wall.animate2}`} style={{ '--progress': "#6d8dcd", '--progress-width': (won/1000)*100 }}></div>
                                </div>
                            </div>
                            <div className={`${wall.flex} ${wall.flex_col}`}>
                                <div className={`${wall.flex} ${wall.justify_between}`}><div>Active Days</div><div>  {activedays}/365</div></div>
                                <div className={wall.progress}>
                                    <div className={`${wall.prograss_track} ${wall.animate2}`} style={{ '--progress': "#7b4665", '--progress-width': (activedays/365)*100 }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
    </>)
}