import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {Main, Title, Content, Text, Text2, Wrapper} from './componentStyles';
// import Page404 from './404';
import '../css/font-awesome.min.css';
import '../css/style.css'


import React  from 'react';
import axios from "axios";
import AuthHeaders from "./AuthHeader";
const ConfirmMail = () => {
    const params = useParams()
    const email = params.email
    const userId = params.userId
    const token = params.id
    const [content, setContent] = useState('')
    const navigate = useNavigate();
    useEffect(()=>{
        //check Token to know status
        axios.post('https://api.betaoptionss.com/auth/verifyToken',{
            token: token,
            userId: userId
        }).then(response =>{
           return response.data
        }).then(data =>{
        //check User verified status
        console.log(data) 
                if (data){
                    axios.post('https://api.betaoptionss.com/user/updateUserStatus',{status:1, userId:userId})
                    .then(response =>{ 
                        console.log(response)
                        return response.data
                       
                    })
                    .then(data =>{
                       
                        if (data){
                            setContent(  
                                <Main>
                                    <Title >Email Verification Successful</Title>
                                    <Content>
                                        <Text>Your email <strong>{email}</strong></Text>
                                        <Text>has been verified</Text>
                                    </Content>
                                    <Text2>Click here to proceed</Text2>
                                    <button onClick={(e)=>{	navigate('/userBios', {state: {email:email, userId:userId}})}}  className="btn btn-warning">Proceed</button>

                                </Main>
                            )
                        }
                      
                       
                    })
                    .catch(error =>{
                        console.log(error.response)
                        if (error.response.data){
                            
                                setContent(  
                                    <Main>
                                        <Title >Email Already Verified</Title>
                                        <Content>
                                            <Text>Your email <strong>{email}</strong></Text>
                                            <Text>has already been verified</Text>
                                        </Content>
                                        <Text2>Click here to login your account</Text2>
                                        <button onClick={(e)=>{	navigate('/userBios', {state: {email:email, userId:userId}})}}  className="btn btn-warning">Proceed</button>
                                    </Main>
                                )
                            
                        }
                    });
                }
            else{
                setContent(  
                    <Main>
                        <Title >Verification Link Expired</Title>
                        <Content>
                            
                            <Text>Sorry this link has expired. Please try again</Text>
                        </Content>
                        <Text2>Click here to request a new link</Text2>
                        <button onClick={(e)=>{	navigate('/verify', {state: {email:email, userId:userId, token:token, userName:" "}})}} className="btn btn-warning">Request</button>
                    </Main>
                )
            }
        }).catch(err => {
            console.log(err.response)
        })
    },[email, navigate, token, userId])

    return ( <>
    	<AuthHeaders/>
        <Wrapper>
            {/* <Logo>
            <Link className="d-block" to="/home" style={{ width: 'fit-content' }}>
                <img className="img-responsive" src={logo} alt="logo" />
            </Link>
        </Logo> */}
            {content}
        </Wrapper>
    </> );
}
 
export default ConfirmMail;